import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import { useSwipeable } from "react-swipeable";
import { styled } from "styled-components";

import ScrollContainer from 'react-indiana-drag-scroll'

import './WindowCard.scss'

import ExitCross from '../../../static/images/card-exit-cross-black.svg'
import ArrowLeft from '../../../static/images/card-arrow-carousel-left.svg'
import ArrowRight from '../../../static/images/card-arrow-carousel-right.svg'

import { ReactComponent as LabelLine } from '../../../static/images/birds/label_line.svg'


const WindowCardBirdViewProjectDiv = styled.div`

    .window-card-bird-project-line {
        svg {
            opacity: ${({status}) => status ? '1' : '0'};
            pointer-events: ${({status}) => status ? 'all' : 'none'};
        }
    }
    
    .window-card-bird-project-polygon {
        svg {
            path {
                opacity: ${({status}) => status ? '1' : '0.8'};
    
                &:hover {
                    opacity: ${({status}) => status ? '1' : '0.9'};
                }
            }
        } 
    }
    
    .window-card-bird-project-label {
        img {
            opacity: ${({status}) => status ? '1' : '0'};
            pointer-events: ${({status}) => status ? 'all' : 'none'};
        }
    }
`

function WindowCardBirdViewProject({ 
    project, 
    imageWidth, 
    imageHeight,
    activeProject,
    setActiveProject,
    setActiveSmallCard,
    setSmallCardContent
}) {

    const lineLength = project.lineLength ? project.lineLength : 91 
    const anchor = project.anchor ? project.anchor : 0 
    
    const label = new Image()
    label.src = project.icon;

    const sizeCoef = 0.7
    const labelWidth = label.width * sizeCoef
    const labelHeight = label.height * sizeCoef

    const svgRef = useRef(null)
    const [svgWidth, setSvgWidth] = useState(0)
    const [svgHeight, setSvgHeight] = useState(0)

    useEffect(() => {
        const svgElement = svgRef.current
        
        if (svgElement) {
            const pathElement = svgElement.querySelector('path')

            if (pathElement) {
                pathElement.addEventListener('click', () => setIsActive(true))
                pathElement.setAttribute('fill', '#007349')
                pathElement.setAttribute('fill-opacity', '0.4')
                pathElement.setAttribute('stroke', '#4DAC89')
                pathElement.setAttribute('stroke-width', '3')
            }

            setSvgWidth(svgElement.clientWidth)
            setSvgHeight(svgElement.clientHeight)
        }
    }, [])

    const [isActive, setIsActive] = useState(false)
    useEffect(() => {
        if (isActive) {
            setActiveProject(project.title)
        }
    }, [isActive])

    useEffect(() => {
        setIsActive(false)
        setActiveSmallCard(false)
    }, [activeProject])
    
    return (
        <WindowCardBirdViewProjectDiv 
            className="window-card-bird-project"
            // status={activeProject === project.title}
            status={true}
        >
            <div className="window-card-bird-project-line">
                <svg
                    width={imageWidth * (3 / 3840)}
                    height={imageHeight * (lineLength / 2160)}
                    style={{ 
                        transform: `translate(
                            ${imageWidth * ((project.coords[0] + svgWidth/2) / 3840)}px,
                            ${imageHeight * ((project.coords[1] - (lineLength - 6)) / 2160)}px)
                        `
                    }} 
                    viewBox={"0 0 3 " + lineLength} 
                    fill="none" 
                    xmlns="http://www.w3.org/2000/svg"
                >
                    <rect width="3" height={lineLength} fill="#1F4546"/>
                </svg>
            </div>

            <div className="window-card-bird-project-polygon">
                {
                    svgHeight === 0 ? (
                        <project.polygon 
                            ref={svgRef}
                            style={{ 
                                transform: `translate(
                                    ${imageWidth * (project.coords[0] / 3840)}px,
                                    ${imageHeight * (project.coords[1] / 2160)}px)
                                `,
                            }} 
                        />
                    ) : (
                        <project.polygon 
                            width={imageWidth * (svgWidth / 3840)}
                            height={imageHeight * (svgHeight / 2160)}
                            style={{ 
                                transform: `translate(
                                    ${imageWidth * (project.coords[0] / 3840)}px,
                                    ${imageHeight * (project.coords[1] / 2160)}px)
                                `,
                        }} />
                    )
                }
            </div>

            <div className="window-card-bird-project-label">
                <img 
                    style={{ 
                        transform: `translate(
                            ${imageWidth * ((project.coords[0] + svgWidth/2 + anchor) / 3840 - (labelWidth/2 / 1120))}px,
                            ${imageHeight * ((project.coords[1] - (lineLength - 8)) / 2160 - (labelHeight / 640))}px)
                        `,
                        width: `${imageWidth * (labelWidth / 1120)}px`,
                        height: `${imageHeight * (labelHeight / 640)}px`
                    }}
                    className="window-card-bird-project-img"
                    src={project.icon} 
                    alt="window-card-bird-img"
                    status={activeProject === project.title}
                    onClick={() => {
                        if (project.type === 'link') {
                            if (project.link) {
                                window.open(project.link, "_blank")
                            }
                            setActiveSmallCard(false)
                        } else if (project.type === 'card') {
                            setActiveSmallCard(true)
                            setSmallCardContent(project)
                        }
                        
                    }}
                />    
            </div>
        </WindowCardBirdViewProjectDiv>
    )
}


const WindowCardBirdInnerDiv = styled.div`
    transform: scale(${({scale}) => scale});
`

export function WindowCardBirdView({ 
    background, 
    projects,

    activeSmallCard,
    setActiveSmallCard,
    setSmallCardContent
}) {
    const [imageWidth, setImageWidth] = useState(500)
    const [imageHeight, setImageHeight] = useState(500)
    const imageRef = useRef(null)

    useLayoutEffect(() => {
        const image = imageRef.current

        if (image) {

            const resizeObserver = new ResizeObserver(entries => {
                if (entries && entries.length > 0) {
                    const newWidth = entries[0].contentRect.width
                    const newHeight = entries[0].contentRect.height
                    setImageWidth(newWidth)
                    setImageHeight(newHeight)
                }

                setActiveSmallCard(false)
            });

            resizeObserver.observe(image)

            return () => {
                resizeObserver.unobserve(image)
            };
        }
    }, []);

    // Active Project(for opacity and label)
    const [activeProject, setActiveProject] = useState('')

    // Scale(zoom)

//     const [pos, setPos] = useState({ x: 0, y: 0, scale: 1 });

//   const onScroll = (e) => {
//     const delta = e.deltaY * -0.01;
//     let newScale = pos.scale + delta;
    
//     if (newScale < 1) {
//       newScale = 1;
//     } else if (newScale > 5) {
//       newScale = 5;
//     }

//     const rect = e.currentTarget.getBoundingClientRect();
//     const offsetX = (e.clientX - rect.left - pos.x) / pos.scale;
//     const offsetY = (e.clientY - rect.top - pos.y) / pos.scale;

//     const newX = e.clientX - offsetX * newScale;
//     const newY = e.clientY - offsetY * newScale;

//     setPos({
//       scale: newScale,
//       x: newX < 0 ? 0 : newX,
//       y: newY < 0 ? 0 : newY,
//     });
//   };
    return (
        <ScrollContainer 
            className="window-card-bird"
            onScroll={() => setActiveSmallCard(false)}
        >
            <WindowCardBirdInnerDiv 
                className="window-card-bird-inner"
                // onWheelCapture={onScroll}
                // style={{
                //     transformOrigin: "0 0",
                //     transform: `translate(${pos.x}px, ${pos.y}px) scale(${pos.scale})`
                //   }}
            >
            {
                projects.map((project) => (
                        
                        <WindowCardBirdViewProject
                            project={project}
                            imageWidth={imageWidth}
                            imageHeight={imageHeight}

                            activeProject={activeProject}
                            setActiveProject={setActiveProject}
                        
                            setActiveSmallCard={setActiveSmallCard}
                            setSmallCardContent={setSmallCardContent}
                        />
                    )
                )
            }
            <img
                className="window-card-bird-bg" 
                src={background} 
                alt="window-card-bird-bg" 
                ref={imageRef}

                onClick={() => {
                    setActiveSmallCard(false)
                    setActiveProject('')
                }}
            />
            </WindowCardBirdInnerDiv>
        </ScrollContainer>
    )
}


const CircleDiv = styled.div`
    background-color: ${({status}) => status ? "rgba(255, 255, 255, 1)" : "rgba(255, 255, 255, 0.5)"};
`

function WindowCardGalleryCircles({ previews, setImageIndex, activeCircle, setActiveCircle }) {
    return (
        <div className="window-card-gallery-circles">
            <div className="window-card-gallery-circles-inner">
                {
                    previews.map((preview) => (
                        <CircleDiv status={activeCircle === preview} className="window-card-gallery-circle" onClick={() => {
                            setActiveCircle(preview)
                            setImageIndex(previews.indexOf(preview))
                        }}></CircleDiv>
                    ))
                }
            </div>
        </div>
    )
}


const WindowCardGalleryDiv = styled.div`
    background-image: url(${({image}) => image});  
`

export function WindowCardGallery({ 
    cardActiveImageIndex, 
    images, 
    setActiveWindow, 
    setActiveCard,
    setCardImageIndex
}) {

    // ---------------------------------
    // *** Code needs to be replaced ***
    // ---------------------------------
    
    const [activeImage, setActiveImage] = useState()
    const [imageIndex, setImageIndex] = useState(cardActiveImageIndex)
    const [activeCircle, setActiveCircle] = useState()
    const [imagesList, setImagesList] = useState(images)

    useEffect(() => {
        let image = imagesList[imageIndex]
        setActiveImage(image)
        setActiveCircle(image)
        setCardImageIndex(imageIndex)
    }, [imageIndex])


    // Track updates from card
    useEffect(() => {
        setImagesList(images)
        setActiveImage(images[imageIndex])
    }, [images])

    useEffect(() => {
        setImageIndex(cardActiveImageIndex)
    }, [cardActiveImageIndex])


    const changeImage = (action) => {
        let nextIndex = imageIndex+action
        if (nextIndex > imagesList.length-1) {
            setImageIndex(0)
        } else if (nextIndex < 0) {
            setImageIndex(imagesList.length-1)
        } else {
            setImageIndex(nextIndex)
        }
    }
    
    // Swipe handlers
    const handlers = useSwipeable({
        onSwipedLeft: () => changeImage(1),
        onSwipedRight: () => changeImage(-1),
        swipeDuration: 500,
        preventScrollOnSwipe: true,
        preventDefaultTouchmoveEvent: true,
        trackMouse: true
    })

    return (
        <WindowCardGalleryDiv 
            className="window-card-gallery"
            image={activeImage}
            {...handlers}
        >
            <div 
                className="window-card-gallery-arrow window-card-gallery-arrow-left" 
                onClick={() => changeImage(-1)}
            >
                <img src={ArrowLeft} alt="arrow-left" />
            </div>
            <WindowCardGalleryCircles 
                previews={images}
                setImageIndex={setImageIndex}
                activeCircle={activeCircle}
                setActiveCircle={setActiveCircle}
            />
            <div 
                className="window-card-gallery-arrow window-card-gallery-arrow-right"
                onClick={() => changeImage(1)}
            >
                <img src={ArrowRight} alt="arrow-right" />
            </div>
            {/* <div className="window-card-gallery-back" /> */}
        </WindowCardGalleryDiv>
    )
}


const WindowCardDiv = styled.div`
    opacity: ${({status}) => status ? '1' : '0'};
    pointer-events: ${({status}) => status ? 'all' : 'none'} !important;
`

export default function WindowCard({ windowContent, setWindowContent, activeWindow, setActiveWindow, setActiveSmallCard }) {
    return (
        <div className="window-card">
            <WindowCardDiv status={activeWindow && windowContent} className="window-card-container">
                <div 
                    className="window-card-cross" 
                    onClick={() => {
                        setActiveWindow(false)
                        setActiveSmallCard(false)
                        // setActiveCard(true)
                        setWindowContent(null)
                    }}
                >
                    <img src={ExitCross} alt="exit-cross" />
                </div>
                <div className="window-card-inner">
                    { windowContent && (
                        windowContent
                    ) }
                </div>
            </WindowCardDiv>
        </div>
    )
}